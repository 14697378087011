import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { Card, CardContent, Grid, Tab, Alert, Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Show,
  TabbedShowLayout,
  Title,
  TranslatableInputs,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
  useNotify,
} from "react-admin";
import { RichTextInput, RichTextInputToolbar } from "ra-input-rich-text";
import { useSync, usePrismicSync, usePublish, useAmplifyPrdRebuild, useAmplifyAccRebuild } from "../hooks/useSync";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { __ENV__, ENV, processENV } from "../api";
import { CloudSync, InfoTwoTone, MailOutline, TuneOutlined } from "@mui/icons-material";
import dataProvider from "../providers/grapheneDataProvider2";
import CountryMarketoPartitionForm from "../components/marketo/CountryMarketoPartitionForm";
const Settings = () => {
  const mutateSyncStripe = useSync();
  const mutateSyncPrismic = usePrismicSync();
  const mutatePublish = usePublish();
  const mutateAmplifyAccRebuild = useAmplifyAccRebuild();
  const mutateAmplifyPrdRebuild = useAmplifyPrdRebuild();

  const [syncStripe, setSyncStripe] = useState({
    v2ProductsSync: {
      productSynced: 0,
    },
    pricesSync: {
      pricesSynced: 0,
    },
    taxRateSync: {
      taxRateSynced: 0,
    },
    v2BundleSync: {
      bundleSynced: 0,
    },
  });

  const syncStripeHandle = () => {
    setSyncStripe({
      v2ProductsSync: {
        productSynced: 0,
      },
      pricesSync: {
        pricesSynced: 0,
      },
      taxRateSync: {
        taxRateSynced: 0,
      },
      v2BundleSync: {
        bundleSynced: 0,
      },
    });
    mutateSyncStripe.mutate(
      {},
      {
        onSuccess: (data) => {
          setSyncStripe(data);
        },
      }
    );
  };
  const [syncPrismic, setSyncPrismic] = useState({
    prismicProductsSync: {
      productsSynced: 0,
    },
    prismicBundlesSync: {
      bundlesSynced: 0,
    },
  });

  const syncPrismicHandle = () => {
    setSyncPrismic({
      prismicProductsSync: {
        productsSynced: 0,
      },
      prismicBundlesSync: {
        bundlesSynced: 0,
      },
    });
    mutateSyncPrismic.mutate(
      {},
      {
        onSuccess: (data) => {
          setSyncPrismic(data);
        },
      }
    );
  };
  const [publish, setPublish] = useState({
    v2BundleSync: {
      bundleSynced: 0,
    },
    v2Publish: {
      productPublished: 0,
      bundlePublished: 0,
    },
  });

  const publishHandle = () => {
    setPublish({
      v2BundleSync: {
        bundleSynced: 0,
      },
      v2Publish: {
        productPublished: 0,
        bundlePublished: 0,
      },
    });
    mutatePublish.mutate(
      {},
      {
        onSuccess: (data) => {
          setPublish(data);
        },
      }
    );
  };

  const [responseAcc, setResponseAcc] = useState("");
  const [responsePrd, setResponsePrd] = useState("");
  const amplifyAccRebuildHandle = () => {
    setResponseAcc("");
    mutateAmplifyAccRebuild.mutate(
      {},
      {
        onSuccess: (data) => {
          setResponseAcc(data.amplifyAccRebuild.response);
        },
      }
    );
  };
  const amplifyPrdRebuildHandle = () => {
    setResponsePrd("");
    mutateAmplifyPrdRebuild.mutate(
      {},
      {
        onSuccess: (data) => {
          setResponsePrd(data.amplifyPrdRebuild.response);
        },
      }
    );
  };
  const MarketoSettingsHeader = () => {
    return (
      <span style={{ display: "" }}>
        <MailOutline />
        <TuneOutlined />
      </span>
    );
  };
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const LanguagePartitionForm = () => {
    const [translationLocaleKeys, setTranslationLocaleKeys] = useState(null);

    let t_keys = ["en"];
    dataProvider.getMany("userLanguages", {}).then(({ data }) => {
      data.forEach((lang) => {
        t_keys.push(`${lang.id}`);
      });
      setTranslationLocaleKeys([...new Set(t_keys)]);
    });

    if (!translationLocaleKeys) {
      return <p>Loading...</p>;
    }
    console.log("locales are ", translationLocaleKeys);
    return (
      <>
        <Alert severity="info">"en" fields are required</Alert>
        <TranslatableInputs
          locales={translationLocaleKeys}
          defaultLocale={"en"}
          sx={{ minWidth: "600px" }}
          fullWidth
          groupKey="languages"
        >
          <TextInput source="" label="something" />
        </TranslatableInputs>
      </>
    );
  };

  return (
    <TabContext value={value}>
      <TabList value="1" aria-label="icon position tabs example" onChange={handleChange}>
        <Tab icon={<CloudSync />} iconPosition="start" label="Sync & Publish" value="1" />
        <Tab icon={<MarketoSettingsHeader />} iconPosition="start" label="Marketo Settings" value="2" />
      </TabList>
      <TabPanel value="1">
        <Grid>
          <Grid item>
            <Card>
              <Title title="Settings" />
              <CardContent>
                <h1>Settings</h1>
                <Typography variant="body1">REACT_APP_KIDS_API_URL:{processENV.REACT_APP_KIDS_API_URL}</Typography>
                <Typography variant="body1">REACT_APP_SITE_URL:{processENV.REACT_APP_SITE_URL}</Typography>
                <Typography variant="body1">REACT_APP_DEV:{processENV.REACT_APP_DEV}</Typography>
                <Typography variant="body1">REACT_APP_ENV_NAME:{processENV.REACT_APP_ENV_NAME}</Typography>
              </CardContent>
            </Card>
            <Card sx={{ mt: 5 }}>
              <CardContent>
                <Typography variant="body1"></Typography>
                {mutateSyncStripe.isLoading ? (
                  <Button variant="contained">...</Button>
                ) : (
                  <Button variant="contained" onClick={syncStripeHandle}>
                    Start Sync Stripe
                  </Button>
                )}
                <Typography variant="h4">Results</Typography>
                <Typography variant="h5">productsSync: {syncStripe.v2ProductsSync.productSynced}</Typography>
                <Typography variant="h5">pricesSync: {syncStripe.pricesSync.pricesSynced}</Typography>
                <Typography variant="h5">taxRateSync: {syncStripe.taxRateSync.taxRateSynced}</Typography>
                <Typography variant="h5">bundleSync: {syncStripe.v2BundleSync.bundleSynced}</Typography>
              </CardContent>
            </Card>
            <Card sx={{ mt: 5 }}>
              <CardContent>
                <Button variant="contained" onClick={publishHandle}>
                  Publish Products and Bundles
                </Button>
                <Typography variant="h4">Results</Typography>
                <Typography variant="h5">bundleSynced: {publish.v2BundleSync.bundleSynced}</Typography>
                <Typography variant="h5">bundlePublished: {publish.v2Publish.bundlePublished}</Typography>
                <Typography variant="h5">productPublished: {publish.v2Publish.productPublished}</Typography>
              </CardContent>
            </Card>
            <Card sx={{ mt: 5 }}>
              <CardContent>
                <Button variant="contained" onClick={amplifyAccRebuildHandle}>
                  Trigger ACC rebuild
                </Button>
                <Typography variant="h4">Results</Typography>
                <Typography variant="h5">Response Status:{responseAcc}</Typography>
              </CardContent>
            </Card>
            <Card sx={{ mt: 5 }}>
              <CardContent>
                <Button variant="contained" onClick={amplifyPrdRebuildHandle}>
                  Trigger PRD rebuild
                </Button>
                <Typography variant="h4">Results</Typography>
                <Typography variant="h5">Response Status:{responsePrd}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value="2">
        <Grid>
          <Title title="Settings" />
          <Grid item>
            <CountryMarketoPartitionForm />
          </Grid>
        </Grid>
      </TabPanel>
    </TabContext>
  );
};

export default Settings;
