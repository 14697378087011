import { Badge } from "@mui/material";
import { useCallback } from "react";
import {
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  Labeled,
  EditButton,
  FilterButton,
  useUpdate,
  List,
  Pagination,
  BulkDeleteButton,
  SaveButton,
  DeleteButton,
  SelectColumnsButton,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  BooleanFieldProps,
  WrapperField,
  useNotify,
  useRedirect,
  BooleanInput,
  SelectInput,
  useCreate,
  ArrayField,
  Edit,
  ReferenceArrayField,
  NumberField,
  ReferenceArrayInput,
  SelectArrayInput,
  DateField,
  NumberInput,
  BooleanField,
} from "react-admin";
import { Grid, MenuItem } from "@mui/material";

const CancellationCouponBulkActionButtons = () => (
  <>
    <BulkDeleteButton label="Archive & Deactivate" />
  </>
);
const MyPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />;
const MyToolbar = (props: any) => {
  const redirect = useRedirect();
  const notify = useNotify();
  return (
    <Toolbar {...props} sx={{ justifyContent: "space-between" }}>
      <SaveButton label="Update" />
      <DeleteButton label="Archive & Deactivate" sx={{ textAlign: "right" }} />
    </Toolbar>
  );
};

export const CancellationCouponList = (props: any) => {
  const bundleFilters = [
    <TextInput label="Active" source="active__icontains" alwaysOn />,
    <TextInput label="Coupon Id" source="couponId__icontains" alwaysOn />,
    <TextInput label="Coupon Name" source="couponName__icontains" alwaysOn />,
  ];

  return (
    <List filters={bundleFilters} {...props} sort={{ field: "active", order: "DESC" }} pagination={<MyPagination />}>
      <Datagrid bulkActionButtons={<CancellationCouponBulkActionButtons />}>
        <TextField label="Coupon Id" source="couponId" />
        <DateField label="Coupon Created At" source="createdAt" />
        <TextField label="Coupon Name" source="couponName" />
        <ReferenceArrayField label="Tags" reference="productTags" source="tags" perPage={100}></ReferenceArrayField>
        <BooleanField label="Active" source="active"></BooleanField>
        <TextField label="Coupon Percentage" source="couponPercentage"></TextField>
        <NumberField label="Coupon Amount" source="couponAmount"></NumberField>
        <TextField label="Currency" source="currency" />

        <WrapperField label="Actions">
          <EditButton />
        </WrapperField>
      </Datagrid>
    </List>
  );
};

const CancellationCouponCreateForm: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Labeled label="Coupon Name" fullWidth={true}>
          <TextInput source="couponName" fullWidth={true} />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Tags" fullWidth={true}>
          <ReferenceArrayInput source="tags" reference="productTags" perPage={100}>
            <SelectArrayInput optionText="name">
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            </SelectArrayInput>
          </ReferenceArrayInput>
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Active" fullWidth={true}>
          <SelectInput
            source="active"
            defaultValue={true}
            choices={[
              { id: true, name: "True" },
              { id: false, name: "False" },
            ]}
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Coupon Percentage" fullWidth={true}>
          <TextInput source="couponPercentage" helperText="This should be the same as in stripe" fullWidth={true} />
        </Labeled>
      </Grid>

      <Grid item xs={12} sm={6}>
        <NumberInput
          source="couponAmount"
          helperText="If not percentage specified, please enter how much discount to be applied. This should be the same as in stripe"
          fullWidth={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput
          label="Currency"
          source="currency"
          fullWidth={true}
          choices={[
            { id: "eur", name: "EUR" },
            { id: "gbp", name: "GBP" },
            { id: "usd", name: "USD" },
          ]}
          alwaysOn
        />
        ,
      </Grid>
    </Grid>
  );
};

const CancellationCouponEditForm: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Labeled
          label="IF NEED TO CHANGE THE COUPON AMOUNT, PLEASE DISABLE THIS COUPON BY SETTING 'active' FIELD TO 'FALSE' AND CREATE A NEW COUPON"
          fullWidth={true}
        >
          <TextInput disabled={true} source="">
            IF NEED TO CHANGE THE COUPON AMOUNT, PLEASE DISABLE THIS COUPON BY SETTING 'active' FIELD TO 'FALSE' AND
            CREATE A NEW COUPON{" "}
          </TextInput>
        </Labeled>
        <Labeled label="Coupon Name" fullWidth={true}>
          <TextField source="couponName" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Tags" fullWidth={true}>
          <ReferenceArrayInput source="tags" reference="productTags" perPage={100}>
            <SelectArrayInput optionText="name">
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            </SelectArrayInput>
          </ReferenceArrayInput>
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Active" fullWidth={true}>
          <SelectInput
            source="active"
            defaultValue={true}
            choices={[
              { id: true, name: "True" },
              { id: false, name: "False" },
            ]}
          />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Coupon Percentage" fullWidth={true}>
          <TextField source="couponPercentage" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Coupon Amount" fullWidth={true}>
          <NumberField source="couponAmount" />
        </Labeled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Labeled label="Currency" fullWidth={true}>
          <TextField source="currency" defaultValue={"EUR"} />
        </Labeled>
      </Grid>
    </Grid>
  );
};

export const CancellationCouponCreate = () => {
  const [create] = useCreate();
  const save = useCallback(
    async (values: any) => {
      try {
        await create("cancellationCoupon", { data: values }, { returnPromise: true });
      } catch (error: any) {
        if (error!.body!.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  return (
    <Create>
      <SimpleForm>
        <CancellationCouponCreateForm></CancellationCouponCreateForm>
      </SimpleForm>
    </Create>
  );
};

export const CancellationCouponEdit = () => {
  const [update, { isLoading, error }] = useUpdate();
  const save = useCallback(
    async (values: any) => {
      try {
        await update(
          "cancellationCoupon",
          { id: values.id, data: values, previousData: values },
          { returnPromise: true }
        );
      } catch (error: any) {
        if (error!.body!.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [update]
  );

  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm toolbar={<MyToolbar />}>
        <CancellationCouponEditForm></CancellationCouponEditForm>
      </SimpleForm>
    </Edit>
  );
};

export const MyActions = ({ showCreate = false }) => {
  return (
    <TopToolbar>
      {showCreate ? <CreateButton /> : ""}
      <FilterButton />
      <SelectColumnsButton />
    </TopToolbar>
  );
};
