import * as React from "react";
import { useState, useEffect } from "react";
import { Card, CardContent, Alert } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  required,
  useNotify,
} from "react-admin";
import dataProvider from "../../providers/grapheneDataProvider2";

const validateCountryMarketoPartitionForm = (form) => {
  let errors = {};
  let mapped_countries = [];
  form.mappings.forEach((mapping) => {
    if (!mapped_countries.includes(mapping.country)) {
      mapped_countries.push(mapping.country);
    } else {
      errors.mappings = `${mapping.country} is already mapped elsewhere.`;
    }
  });
  return errors;
};

const useCountryMarketoMappings = () => {
  const [mappings, setMappings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchMappings = async () => {
    try {
      setLoading(true);
      const response = await dataProvider.getList("marketoConfig", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      });
      response.data.forEach((config) => {
        if (config.name == "country_to_partition_mapping") {
          setMappings(config.settings);
        }
      });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const saveMappings = async (newMappings) => {
    try {
      setLoading(true);
      await dataProvider.update("marketoConfig", {
        id: "country_to_partition_mapping",
        data: { settings: newMappings },
      });
      await fetchMappings();
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMappings();
  }, []);

  return { mappings, loading, error, saveMappings };
};

const CountryMarketoPartitionForm = () => {
  const notify = useNotify();
  const { mappings, loading, error, saveMappings } = useCountryMarketoMappings();

  if (loading) return <div>Loading...</div>;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  const defaultValues = {
    mappings: Array.isArray(mappings)
      ? mappings.map((mapping) => ({
          country: mapping.country,
          partition_id: mapping.partition_id,
        }))
      : [],
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Country to Marketo Partition Mapping
        </Typography>
        <Alert severity="info" sx={{ mb: 2 }}>
          Map countries to Marketo partitions.
        </Alert>
        <Alert severity="warning" sx={{ mb: 2 }}>
          This affects the partition used for leads based on their country.
        </Alert>

        <SimpleForm
          defaultValues={defaultValues}
          validate={validateCountryMarketoPartitionForm}
          onSubmit={(values) => {
            saveMappings(values.mappings)
              .then(() => {
                notify("Mappings saved successfully");
              })
              .catch((error) => {
                notify("Error saving mappings", { type: "error" });
              });
          }}
        >
          <ArrayInput source="mappings" sx={{ width: "100%" }}>
            <SimpleFormIterator disableReordering disableClear fullWidth inline>
              <ReferenceInput source="country" reference="allCountries" label="Country">
                <AutocompleteInput
                  optionText={(record) => `${record.flag} ${record.name}`}
                  validate={required()}
                  sx={{ minWidth: "30%" }}
                  filterToQuery={(searchText) => {
                    if (!searchText) return {};
                    if (searchText.length === 2) {
                      return { id__exact: searchText.toUpperCase() };
                    }
                    return { name__icontains: searchText };
                  }}
                />
              </ReferenceInput>
              <ReferenceInput source="partition_id" reference="marketoLeadPartitions">
                <SelectInput
                  label="Lead Partition"
                  optionText={(record) => (
                    <span>
                      <b>{record.name}</b> - #{record.id}
                    </span>
                  )}
                  validate={required()}
                  sx={{ minWidth: "60%" }}
                />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </CardContent>
    </Card>
  );
};

export default CountryMarketoPartitionForm;
